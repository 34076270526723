import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">

<path d="M1554 2197 c-76 -44 -373 -271 -382 -292 -28 -60 -33 -51 230 -401
87 -115 158 -212 158 -215 0 -3 -47 -38 -105 -77 l-104 -72 -66 40 c-36 22
-67 40 -70 40 -8 0 111 -236 143 -282 72 -104 90 -100 348 72 115 77 217 152
226 167 41 65 37 74 -223 423 -80 107 -148 200 -152 206 -3 6 28 36 76 71 l83
60 74 -43 c41 -24 78 -43 82 -43 15 -2 -149 276 -187 316 -33 35 -48 43 -76
43 -19 -1 -43 -6 -55 -13z m124 -62 c35 -40 131 -205 119 -205 -3 0 -23 6 -45
14 -36 13 -42 13 -73 -6 -82 -49 -139 -100 -139 -125 0 -28 0 -28 170 -258 69
-93 145 -197 168 -230 42 -61 51 -99 33 -134 -5 -9 -101 -78 -213 -154 -236
-159 -254 -165 -307 -100 -30 36 -112 194 -105 202 1 2 17 -2 34 -8 37 -13 56
-5 168 72 88 59 103 84 77 124 -26 40 -186 257 -282 381 -45 59 -85 118 -88
133 -10 44 11 75 92 133 43 31 125 90 182 131 129 93 150 96 209 30z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
